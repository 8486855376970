
<template>
    <div data-app>
        
        <div v-bind:class="currentVideo == 'explain' || currentVideo == 'completed'? 'backdrop':'nobackdrop'">
            <!-- <div class="video-miss-arrow" v-if="(currentVideo == 'explain' && !learndialogue) || (currentVideo == 'completed' && !learndialogue)">
                <p>Ve el video para comenzar la sección</p>
                <img :src="require('@/assets/images/videomissarrow.svg')" style="margin-right:435px;">
            </div> -->
        </div>

        <div v-bind:class="this.homeData.audible_bonus && this.homeData.audible_bonus[0].status == 'opened'? 'backdrop':'nobackdrop'">
            
        </div>

        <div class="row">
            <UserInfo :begin="begin" :currentVideo="currentVideo"></UserInfo>
        </div>
         
        <v-container>
            <v-row>
                <v-col cols="12">
                    <div class="course-title mt-5">
                        {{parentTitle}}
                    </div>
                </v-col> 
                <v-col md="4" xs="12">
                    <v-row>
                    <v-col class="text-center" cols="12" v-bind:class="currentVideo == 'explain'?'Zindex':''">
                            <div class="explain-byvideo" 
                            v-for="video in homeData.video" 
                            :key = "video._id"  
                            @click="onClickVideo(video)" >
                                <div   >
                                    <img :src="require('@/assets/images/videocard.svg')">
                                    <p class="mb-0 mt-10">La Miss te explica</p>
                                </div>
                            </div>
                        
                    </v-col>


                    <v-col class="text-center" cols="12" v-for="bonus in homeData.audible_bonus" :key = "bonus._id"
                    v-bind:class="bonus.status == 'opened'?'Zindex':''" 
                    id="bonus1">
                            <img :src="bonus.status == 'lock'? require('@/assets/images/card.svg'): require('@/assets/images/card_open.svg')"
                            @click="abacusCal(bonus)">

                            <b-popover target="bonus1" triggers="hover" placement="bottom">
                                <ol>
                                    <li>
                                        En esta sección la plataforma te dictará las operaciones.
                                    </li>
                                    <li>
                                        En caso de que te equivoques la plataforma te indicará el error.
                                    </li>
                                    <li>
                                        En este apartado mientras más operaciones correctas obtengas, la recompensa será mayor.
                                    </li>
                                    <li>
                                        Podrás usar el ábaco para hacer las operaciones, pero no te guiara.
                                    </li>
                                </ol>
                            </b-popover>
                    </v-col>

                    
                    <v-col class="text-center" cols="12" v-for="bonus in homeData.flash_bonus" :key = "bonus._id"
                    v-bind:class="bonus.status == 'opened'?'Zindex':''">
                        <img :src="bonus.status == 'lock'? require('@/assets/images/card.svg'): require('@/assets/images/card_open.svg')" 
                            @click="abacusCal(bonus)">
                    </v-col>

                    </v-row>
                </v-col>
                <v-col md="8" xs="12">
                    <v-row>
                            <v-col class="text-center" v-for="(item, index) in homeData.practice" v-bind:key="item._id" lg="6" md="12" sm="12" :id="item.points < 100?'practice1':'practice2'">
                                        <div class="position-relative">
                                            <div class="lockscreen-body">
                                                <div class="lock-screen" v-if="item.status=='lock'">
                                                    <span class="material-icons">lock</span>
                                                </div> 
                                            </div>
                                            
                                            <div class="lesson-video" @click="abacusCal(item, index)">
                                            <div>
                                                <img :src="require('@/assets/images/abacuspic.png')" style="margin: 20px">
                                                <p>{{item.title}}</p>
                                            </div>
                                            </div>
                                            <div class="avance" v-bind:class="item.status">   
                                                <img :src="require('@/assets/images/directions_run.svg')" v-if="item.status == 'opened'">                     
                                                <img :src="require('@/assets/images/remove_circle.svg')" v-if="item.status == 'lock'">                     
                                                <img :src="require('@/assets/images/check_circle.svg')" v-if="item.status == 'passed'">                     
                                                <p class="avance-title mt-2" v-if="item.status=='opened'">MISIÓN EN PROGRESO</p>
                                                <p class="avance-title mt-2" v-if="item.status=='lock'">MISIÓN BLOQUEADA</p>
                                                <p class="avance-title mt-2" v-if="item.status=='passed'">MISIÓN FINALIZADA</p>
                                                <div class="avance-divider"></div>
                                                <p class="recompensas-title">RECOMPENSAS</p>
                                                <div class="recompensas-body">
                                                    <img :src="require('@/assets/images/moneypic.svg')" width="100">
                                                    <div class="recompensas-content">
                                                        <p v-if="item.status != 'passed'">Termina este reto y recibe <br><b>{{item.points}} monedas</b> de crédito</p>
                                                        <p v-if="item.status == 'passed'">Has ganado <b>{{item.currentPoints}} monedas</b> de crédito</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 

                                        <b-popover target="practice1" triggers="hover" placement="bottom" v-if="item.points < 100">
                                            <ol>
                                                <li>
                                                    En este apartado te ayudaremos paso a paso a realizar el ejercicio, no podrás avanzar a la siguiente operación hasta que el procedimiento y el resultado sean correctos.
                                                </li>
                                                <li>
                                                    En caso de que te equivoques la plataforma te indicará el error.
                                                </li>
                                                <li>
                                                    Puedes obtener <strong>50 Fastcoin.</strong>
                                                </li>
                                            </ol>
                                        </b-popover>

                                        <b-popover target="practice2" triggers="hover" placement="bottom" v-if="item.points >= 100">
                                            <ol>
                                                <li>
                                                    Si la plataforma te indica un error tienes la posibilidad de corregir hasta dos veces , al tercer error podrás avanzar con la operación marcada como incorrecta.
                                                </li>
                                                <li>
                                                    Si tienes un error de clic en el ábaco, la plataforma mostrará un mensaje de advertencia.
                                                </li>
                                                <li>
                                                    Para avanzar a la siguiente misión puedes tener máximo 4 operaciones incorrectas, al 5to error tendrás que comenzar de nuevo.
                                                </li>
                                                <li>
                                                    Puedes obtener <strong>100 Fastcoin.</strong>
                                                </li>
                                            </ol>
                                            <p>
                                                <strong>Nota:</strong> la diferencia con el paso anterior APRENDE Y PRACTICA es que aquí si tendrás operaciones con el resultado erróneo y se marcarán en rojo.
                                            </p>
                                        </b-popover>
                            </v-col>
                            
                            <v-col class="text-center" v-for="(item, index) in homeData.abacus" v-bind:key="item._id" lg="6" md="12" sm="12">
                                        <div class="position-relative">
                                            <div class="lockscreen-body">
                                                <div class="lock-screen" v-if="item.status=='lock'">
                                                    <span class="material-icons">lock</span>
                                                </div> 
                                            </div>
                                            
                                            <div class="lesson-video" @click="abacusCal(item, index)">
                                            <div>
                                                <img :src="require('@/assets/images/abacuspic.png')" style="margin: 20px">
                                                <p>{{item.title}}</p>
                                            </div>
                                            </div>
                                            <div class="avance" v-bind:class="item.status">   
                                                <img :src="require('@/assets/images/directions_run.svg')" v-if="item.status == 'opened'">                     
                                                <img :src="require('@/assets/images/remove_circle.svg')" v-if="item.status == 'lock'">                     
                                                <img :src="require('@/assets/images/check_circle.svg')" v-if="item.status == 'passed'">                     
                                                <p class="avance-title mt-2" v-if="item.status=='opened'">MISIÓN EN PROGRESO</p>
                                                <p class="avance-title mt-2" v-if="item.status=='lock'">MISIÓN BLOQUEADA</p>
                                                <p class="avance-title mt-2" v-if="item.status=='passed'">MISIÓN FINALIZADA</p>
                                                <div class="avance-divider"></div>
                                                <p class="recompensas-title">RECOMPENSAS</p>
                                                <div class="recompensas-body">
                                                    <img :src="require('@/assets/images/moneypic.svg')" width="100">
                                                    <div class="recompensas-content">
                                                        <p v-if="item.status != 'passed'">Termina este reto y recibe <br><b>{{item.points}} monedas</b> de crédito</p>
                                                        <p v-if="item.status == 'passed'">Has ganado <b>{{item.currentPoints}} monedas</b> de crédito</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                            </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>      
        <v-dialog v-model="learndialogue" max-width="1000" max-height="600" class="videoscreen" persistent>
            <vimeo-player ref="player" 
            class="vimeo-player"
            :video-id="videoID" 
            @ended="onInitVideoEnded"
            :player-width="1000"  
            :player-height="600" 
            :options="options">
            </vimeo-player>
         </v-dialog>
    </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/begin/begin.scss";
.Zindex{
    z-index: 200;
}
.noZindex{
    z-index: 9;
}
.AcuZindex{
    opacity: 0.5;
    pointer-events: none;
}
.popover.b-popover {
    background-color: #FFFBD9!important;
    font-size: 14px;
    padding: 10px;
    border: #FFD284 1px solid;
    ::marker {
        color: #ED8400;
        font-weight: 700;
    }
}
</style>

<script>
import UserInfo from "@/view/content/user/Userinfo.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import {
  POST_PROGRESS_DATA,
} from "@/core/services/store/progress.module.js";

import {
  GET_HOME_DATA,
} from "@/core/services/store/data.module.js";

import { mapGetters } from "vuex";


export default {
  name: "sublearnabacus",
  components: {
    UserInfo,
  },
  data() {
      return {
          videoID: "",
          learndialogue: false,
          begin: false,
          currentVideo: "",
          id: "",
          options: {
            muted: false,
            autoplay: true,
            controls: true,
          },
      }
  },
  created() {
    this.id = this.parentId;
    this.loadData();
  },
  
  methods: {
    loadData() {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        this.$store
        .dispatch(GET_HOME_DATA, { parent: this.id })
        .then(() => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            if(this.homeData.video[0].status == 'opened') {
                this.currentVideo = this.homeData.video[0].title;
                // this.$store.dispatch(ADD_BODY_CLASSNAME, "no-scroll");
            }
        })
        .catch(() => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    onClickVideo(val) {
        this.learndialogue = true;
        this.videoID = val.video;
        if(this.currentVideo == "") {
            this.$refs.player.play()
        }
        this.currentVideo = val.title;
    },
    abacusCal(details, index) {
        if(details.status == 'lock') {
            return;
        }
        var pathname = new URL(location.href).pathname;
        localStorage.setItem('previous',pathname);
        if(details.type == 'abacus') {
            this.$router.push({name: 'beginSubItem', params: {
                id: details._id,
                parentTitle: details.title,
                level: `${this.level}.${index+1}`
            }}).catch(()=>{});
            return;
        }
        this.$router.push({
            name: 'lessonDetails',
            params: {
                id: details._id,
                parentTitle: this.parentTitle,
                level: `${this.level}.${index+1}`
            }
        });
    },
    Completed() {
        this.$router.push('/home/acumula/'+1)
    },
    onInitVideoEnded() {
        this.currentVideo = "";
        this.learndialogue = false;

        if(this.homeData.video[0].status == 'opened') {
            if(this.homeData.practice) {
                this.homeData.practice[0].status = 'opened';
            }
            if(this.homeData.abacus) {
                this.homeData.abacus[0].status = 'opened';
            }
            this.homeData.video[0].status = 'passed';
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "no-scroll");

            this.progress({
                    lesson_id: this.homeData.video[0]._id,
                    parent: this.homeData.video[0].parent,
                    type: 'video',
                    status: 'passed',
                    step: this.homeData.video[0].step,
                });
        }
        
    },
    progress(params) {
        this.$store
        .dispatch(POST_PROGRESS_DATA, params)
        .then(() => {
            
        })
        .catch(() => {
            
        });
    }
  },
  computed: {
    ...mapGetters([
        "homeData"
    ]),
    parentTitle: function () {
      return this.$route.params.parentTitle;
    },
    parentId: function () {
      return this.$route.params.id;
    },
    level: function() {
      return this.$route.params.level;
    }
  },
}
</script>